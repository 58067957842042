import { useMemo } from "react"
import { localized, defaultToDash } from "js/includes/common/utils"
import { Box, Flex } from "js/includes/components/Styled"
import SearchableDropDown from "js/includes/components/SearchableDropDown"

const userTypes = new Set(["TECHNICIAN", "END_USER", "CONTACT"])

export const searchableKey = "displayName"
export const valueSelectorKey = "uid"

export const getCurrentUserOption = () => ({
  isCurrentUser: true,
  id: "current_user",
  [valueSelectorKey]: "current_user",
  [searchableKey]: localized("Current User"),
})

const rowHeight = 44

export const AppUsersAndContactsDropdown = ({
  showNoneOption = false,
  showCurrentUserOption = false,
  clientId,
  onSelect: _onSelect,
  value,
  ariaAttributes = {},
  additionalDefaultOptions = [],
  userType,
  isMulti = false,
  isCreatable = false,
  shouldCreate,
  disabled = false,
  enableClear = true,
  ...rest
}) => {
  const onSelect = option => _onSelect(!option || option.isNone ? null : option)

  const { userTypeMapper, noneOption } = useMemo(
    () => ({
      userTypeMapper: {
        TECHNICIAN: localized("Technician"),
        CONTACT: localized("Contact"),
        END_USER: localized("End User"),
      },
      noneOption: {
        isNone: true,
        [valueSelectorKey]: null,
        [searchableKey]: localized("None"),
      },
    }),
    [],
  )

  const considerUserType = userTypes.has(userType)

  return (
    <SearchableDropDown
      {...{
        ariaAttributes,
        enableClear,
        rowHeight,
        width: "100%",
        openOnFocus: true,
        onSelect,
        keepDropdownInView: true,
        value,
        valueSelectorKey,
        searchableKey,
        endpoint: "/app-user-contact/search",
        pageSize: 50,
        isMulti,
        isCreatable,
        shouldCreate,
        disabled,
        additionalDefaultOptions: [
          ...(showNoneOption ? [noneOption] : []),
          ...(showCurrentUserOption ? [getCurrentUserOption()] : []),
          ...additionalDefaultOptions,
        ],
        searchParams: ({ query: searchCriteria }) => ({
          ...(searchCriteria && { searchCriteria }),
          ...(clientId && { clientId }),
          ...(considerUserType && { userType }),
        }),
        rowRenderer: ({ [searchableKey]: displayName, userType, email, isDefaultOption, isNone, isCurrentUser }) => (
          <Flex flexDirection="column" height={`${rowHeight}px`} justifyContent="center">
            {isNone || isCurrentUser || isDefaultOption ? (
              <Box fontWeight="600">{displayName}</Box>
            ) : (
              <>
                <Flex flexGrow="1" alignItems="center">
                  <Box width={considerUserType ? "100%" : "75%"} className="text-ellipsis" fontWeight="600">
                    {defaultToDash(displayName)}
                  </Box>
                  {!considerUserType && (
                    <Box width="25%" className="text-ellipsis" fontWeight="500" textAlign="right">
                      {defaultToDash(userTypeMapper[userType])}
                    </Box>
                  )}
                </Flex>
                <Flex flexGrow="1" className="text-ellipsis">
                  {defaultToDash(email)}
                </Flex>
              </>
            )}
          </Flex>
        ),
        ...rest,
      }}
    />
  )
}
