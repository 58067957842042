import { useMemo, useState, forwardRef } from "react"
import { TextNode } from "lexical"
import { CodeHighlightNode, CodeNode } from "@lexical/code"
import { HashtagNode } from "@lexical/hashtag"
import { AutoLinkNode, LinkNode } from "@lexical/link"
import { ListItemNode, ListNode } from "@lexical/list"
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary"
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin"
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode"
import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin"
import { TablePlugin } from "@lexical/react/LexicalTablePlugin"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table"

import {
  AttachmentPlugin,
  CodeHighlightPlugin,
  ContentEditable,
  ContentInjectorPlugin,
  DragDropPastePlugin,
  ExtendedTextNode,
  FloatingLinkEditorPlugin,
  FloatingTextFormatToolbarPlugin,
  HTMLParserPlugin,
  AutoLinkPlugin,
  ImageNode,
  ImagePlugin,
  LinkPlugin,
  TableActionMenuPlugin,
  TableCellResizerPlugin,
  ListMaxIndentLevelPlugin,
  Placeholder,
  StyledEditor,
  StyledEditorContainer,
  StyledEditorScroller,
  //Styled Components
  StyledEditorShell,
  TabFocusPlugin,
  ToolbarPlugin,
  theme,
} from "@ninjaone/components/src/WYSIWYG"

import { htmlExport } from "js/includes/ticketing/editor/shared/components/WYSIWYGEditor"

const getNodes = ({ imagePluginExcluded }) => [
  HeadingNode,
  QuoteNode,
  HashtagNode,
  LinkNode,
  CodeNode,
  CodeHighlightNode,
  HorizontalRuleNode,
  ListItemNode,
  ListNode,
  TableCellNode,
  TableNode,
  TableRowNode,
  AutoLinkNode,
  ExtendedTextNode,
  { replace: TextNode, with: node => new ExtendedTextNode(node.__text) },
  ...(!imagePluginExcluded ? [ImageNode] : []),
]

const initialConfig = {
  html: { export: htmlExport },
  onError: error => {
    // TODO: Report error to Sentry
    throw error
  },
  theme,
}

export const WYSIWYGEditor = forwardRef(
  (
    {
      onChangePluginProps = {},
      htmlParserPluginProps = {},
      toolbarPluginProps = {},
      imagePluginProps = {},
      richTextPluginProps = {},
      attachmentPluginProps = {},
      dragDropPastePluginProps = {},
    },
    ref,
  ) => {
    const { excluded: imagePluginExcluded = false } = imagePluginProps
    const {
      excluded: dragDropPastePluginExcluded = false,
      cidKey: dragPasteCidKey,
      onUploadImage: dragPasteOnUploadImage,
    } = dragDropPastePluginProps
    const { excluded: attachmentPluginExcluded = false, initialAttachments, onRemoveAttachment } = attachmentPluginProps
    const {
      cidKey: toolbarCidKey,
      onUploadImage: toolbarOnUploadImage,
      onUploadAttachment,
      onUploadAttachmentError,
    } = toolbarPluginProps
    const { onChange } = onChangePluginProps
    const { initialHTML, updateOnChange } = htmlParserPluginProps
    const { placeholderToken } = richTextPluginProps

    const [floatingAnchorElement, setFloatingAnchorElement] = useState(null)
    const [isLinkEditMode, setIsLinkEditMode] = useState(false)

    const nodes = useMemo(() => getNodes({ imagePluginExcluded }), [imagePluginExcluded])

    const onRef = element => {
      if (element !== null) {
        setFloatingAnchorElement(element)
      }
    }

    return (
      <>
        <LexicalComposer
          initialConfig={{
            ...initialConfig,
            nodes,
          }}
        >
          <StyledEditorShell>
            <ToolbarPlugin
              {...{
                sticky: false,
                setIsLinkEditMode,
                ...{
                  cidKey: toolbarCidKey,
                  onUploadImage: toolbarOnUploadImage,
                  onUploadAttachment,
                  onUploadAttachmentError,
                },
              }}
            />
            <StyledEditorContainer>
              <RichTextPlugin
                contentEditable={
                  <StyledEditorScroller id="editor-input">
                    <StyledEditor ref={onRef}>
                      <ContentEditable placeholder={<Placeholder token={placeholderToken} />} />
                    </StyledEditor>
                  </StyledEditorScroller>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <HTMLParserPlugin initialHTML={initialHTML} updateOnChange={updateOnChange} />
              <HistoryPlugin />
              <ClearEditorPlugin />
              {!dragDropPastePluginExcluded && (
                <DragDropPastePlugin {...{ cidKey: dragPasteCidKey, onUploadImage: dragPasteOnUploadImage }} />
              )}
              {!imagePluginExcluded && <ImagePlugin />}
              <ListPlugin />
              <LinkPlugin />
              <AutoLinkPlugin />
              <CodeHighlightPlugin />
              <HorizontalRulePlugin />
              <TablePlugin />
              <TableCellResizerPlugin />
              <HashtagPlugin />
              <OnChangePlugin onChange={onChange} />
              <ContentInjectorPlugin />
              <TabFocusPlugin />
              <TabIndentationPlugin />
              <ListMaxIndentLevelPlugin maxDepth={5} />
              {ref && <EditorRefPlugin editorRef={ref} />}
              {!attachmentPluginExcluded && (
                <AttachmentPlugin cidKey="resourceId" {...{ initialAttachments, onRemoveAttachment }} />
              )}
              {floatingAnchorElement && (
                <>
                  <FloatingTextFormatToolbarPlugin
                    anchorElem={floatingAnchorElement}
                    setIsLinkEditMode={setIsLinkEditMode}
                  />
                  <FloatingLinkEditorPlugin
                    anchorElem={floatingAnchorElement}
                    isLinkEditMode={isLinkEditMode}
                    setIsLinkEditMode={setIsLinkEditMode}
                  />
                  <TableActionMenuPlugin anchorElem={floatingAnchorElement} />
                </>
              )}
            </StyledEditorContainer>
          </StyledEditorShell>
        </LexicalComposer>
      </>
    )
  },
)
